var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[(_vm.isLoading)?_c('trac-loading'):_c('div',[_c('h1',{staticClass:"text-2xl font-bold"},[_vm._v("Verification Portal")]),_c('div',{staticClass:" flex justify-end items-end"},[_c('button',{staticClass:"bg-gray-800 py-3 px-6 rounded-md  text-white",on:{"click":function($event){return _vm.$router.push({
            name: 'ViewDocumentVerification',
            params: {
              id: _vm.merchant_id,
            },
          })}}},[_vm._v(" « Back ")])]),_c('div',{staticClass:" grid grid-cols-1 gap-3 mt-5 "},[_c('div',{staticClass:" flex justify-center flex-col p-5 shadow-lg border-gray-200 border-2 rounded-md"},[_c('h4',{staticClass:"text-xl font-bold"},[_vm._v("Add reason for rejection")]),_c('div',{staticClass:" grid grid-cols-1 gap-4 mt-5"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.disapproveKYC($event)}}},[_c('div',{staticClass:" grid grid-cols-1 gap-5 mt-10"},[_c('div',{staticClass:"flex flex-col pt-4"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.reject_reason),expression:"reject_reason"}],staticClass:"shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline",attrs:{"required":"","placeholder":"Type reason here..."},domProps:{"value":(_vm.reject_reason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.reject_reason=$event.target.value}}})])]),_c('button',{staticClass:"inline-block uppercase shadow  mt-10 mx-3 leading-6 hover:bg-red-300 focus:shadow-outline focus:outline-none text-blue text-xs py-2 px-8 rounded",on:{"click":function($event){return _vm.$router.push({
                  name: 'ViewDocumentVerification',
                  params: {
                    id: _vm.merchant_id,
                  },
                })}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"inline-block uppercase shadow bg-blue-600 mt-10  leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded",attrs:{"type":"submit"}},[_vm._v(" Done ")])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }